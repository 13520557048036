.content {
  display: inline-block;
  width: 100%;
  margin-top: 40px;
  /*margin-bottom: 40px;*/
  text-align: center;
}

.iconBlock {
  display: inline-block;
  vertical-align: top;
  position: absolute;
  right: 25px;
  top: 25px;
}

.actionButtonsContainer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  padding: 20px;
}
