.base {
  left: 0;
  text-decoration: none;
  line-height: 1;
  position: fixed !important;
  z-index: 29 !important;
  top: 0.8em;
  left: -345px;
}

.sidebarIsCollapsed {
  left: 345px;
  transform: translateX(0%);
  transition: transform 200ms ease-in-out 200ms, left 0ms linear 200ms;
}

.sidebarIsNotCollapsed {
  transition: none;
  transform: translateX(-100%);
}
