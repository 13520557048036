:global {
  /* background-position */
  .bg-bottom {
    background-position: bottom;
  }
  .bg-center {
    background-position: center;
  }
  .bg-left {
    background-position: left;
  }
  .bg-left-bottom {
    background-position: left bottom;
  }
  .bg-left-top {
    background-position: left top;
  }
  .bg-right {
    background-position: right;
  }
  .bg-right-bottom {
    background-position: right bottom;
  }
  .bg-right-top {
    background-position: right top;
  }
  .bg-top {
    background-position: top;
  }

  /* background-repeat */
  .bg-repeat {
    background-repeat: repeat;
  }
  .bg-no-repeat {
    background-repeat: no-repeat;
  }
  .bg-repeat-x {
    background-repeat: repeat-x;
  }
  .bg-repeat-y {
    background-repeat: repeat-y;
  }
  .bg-repeat-round {
    background-repeat: round;
  }
  .bg-repeat-space {
    background-repeat: space;
  }

  /* background-size */
  .bg-auto {
    background-size: auto;
  }
  .bg-cover {
    background-size: cover;
  }
  .bg-contain {
    background-size: contain;
  }

  /* bg-opacity */
  .bg-opacity-0 {
    --bg-opacity: 0;
  }
  .bg-opacity-5 {
    --bg-opacity: 0.05;
  }
  .bg-opacity-10 {
    --bg-opacity: 0.1;
  }
  .bg-opacity-20 {
    --bg-opacity: 0.2;
  }
  .bg-opacity-25 {
    --bg-opacity: 0.25;
  }
  .bg-opacity-30 {
    --bg-opacity: 0.3;
  }
  .bg-opacity-40 {
    --bg-opacity: 0.4;
  }
  .bg-opacity-50 {
    --bg-opacity: 0.5;
  }
  .bg-opacity-60 {
    --bg-opacity: 0.6;
  }
  .bg-opacity-70 {
    --bg-opacity: 0.7;
  }
  .bg-opacity-75 {
    --bg-opacity: 0.75;
  }
  .bg-opacity-80 {
    --bg-opacity: 0.8;
  }
  .bg-opacity-90 {
    --bg-opacity: 0.9;
  }
  .bg-opacity-95 {
    --bg-opacity: 0.95;
  }
  .bg-opacity-100 {
    --bg-opacity: 1;
  }
}
