:global {
  .mt-auto,
  .m-auto,
  .my-auto {
    margin-top: auto;
  }
  .mr-auto,
  .m-auto,
  .mx-auto {
    margin-right: auto;
  }
  .mb-auto,
  .m-auto,
  .my-auto {
    margin-bottom: auto;
  }
  .ml-auto,
  .m-auto,
  .mx-auto {
    margin-left: auto;
  }

  .mt-0,
  .m-0,
  .my-0 {
    margin-top: var(--unit-0);
  }
  .mr-0,
  .m-0,
  .mx-0 {
    margin-right: var(--unit-0);
  }
  .mb-0,
  .m-0,
  .my-0 {
    margin-bottom: var(--unit-0);
  }
  .ml-0,
  .m-0,
  .mx-0 {
    margin-left: var(--unit-0);
  }
  .mt-px,
  .m-px,
  .my-px {
    margin-top: var(--unit-px);
  }
  .mr-px,
  .m-px,
  .mx-px {
    margin-right: var(--unit-px);
  }
  .mb-px,
  .m-px,
  .my-px {
    margin-bottom: var(--unit-px);
  }
  .ml-px,
  .m-px,
  .mx-px {
    margin-left: var(--unit-px);
  }
  .mt-1,
  .m-1,
  .my-1 {
    margin-top: var(--unit-1);
  }
  .-mt-1,
  .-m-1,
  .-my-1 {
    margin-top: calc(var(--unit-1) * -1);
  }
  .mr-1,
  .m-1,
  .mx-1 {
    margin-right: var(--unit-1);
  }
  .-mr-1,
  .-m-1,
  .-mx-1 {
    margin-right: calc(var(--unit-1) * -1);
  }
  .mb-1,
  .m-1,
  .my-1 {
    margin-bottom: var(--unit-1);
  }
  .-mb-1,
  .-m-1,
  .-my-1 {
    margin-bottom: calc(var(--unit-1) * -1);
  }
  .ml-1,
  .m-1,
  .mx-1 {
    margin-left: var(--unit-1);
  }
  .-ml-1,
  .-m-1,
  .-mx-1 {
    margin-left: calc(var(--unit-1) * -1);
  }
  .mt-2,
  .m-2,
  .my-2 {
    margin-top: var(--unit-2);
  }
  .-mt-2,
  .-m-2,
  .-my-2 {
    margin-top: calc(var(--unit-2) * -1);
  }
  .mr-2,
  .m-2,
  .mx-2 {
    margin-right: var(--unit-2);
  }
  .-mr-2,
  .-m-2,
  .-mx-2 {
    margin-right: calc(var(--unit-2) * -1);
  }
  .mb-2,
  .m-2,
  .my-2 {
    margin-bottom: var(--unit-2);
  }
  .-mb-2,
  .-m-2,
  .-my-2 {
    margin-bottom: calc(var(--unit-2) * -1);
  }
  .ml-2,
  .m-2,
  .mx-2 {
    margin-left: var(--unit-2);
  }
  .-ml-2,
  .-m-2,
  .-mx-2 {
    margin-left: calc(var(--unit-2) * -1);
  }
  .mt-3,
  .m-3,
  .my-3 {
    margin-top: var(--unit-3);
  }
  .-mt-3,
  .-m-3,
  .-my-3 {
    margin-top: calc(var(--unit-3) * -1);
  }
  .mr-3,
  .m-3,
  .mx-3 {
    margin-right: var(--unit-3);
  }
  .mb-3,
  .m-3,
  .my-3 {
    margin-bottom: var(--unit-3);
  }
  .ml-3,
  .m-3,
  .mx-3 {
    margin-left: var(--unit-3);
  }

  .mt-4,
  .m-4,
  .my-4 {
    margin-top: var(--unit-4);
  }
  .mr-4,
  .m-4,
  .mx-4 {
    margin-right: var(--unit-4);
  }
  .mb-4,
  .m-4,
  .my-4 {
    margin-bottom: var(--unit-4);
  }
  .ml-4,
  .m-4,
  .mx-4 {
    margin-left: var(--unit-4);
  }
}
