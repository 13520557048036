/* // prettier-ignore */

@keyframes :global(spin) {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes :global(ping) {
  75%,
  100% {
    transform: scale(2);
    opacity: 0;
  }
}

@keyframes :global(pulse) {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

@keyframes :global(bounce) {
  0%,
  100% {
    transform: translateY(-25%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: translateY(0);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}

@keyframes :global(wobble) {
  0%, 100%  { transform: translate3D(0, 0, 0); }
  50%  { transform: translate3D(0, -5%, 0); }
}

@keyframes :global(swoosh) {
  25% {
    transform: translate3D(2.5%, 5%, 0);
  }
  50% {
    animation-timing-function: cubic-bezier(0.3, 0.27, 0.07, 1.64);
    transform: translate3D(5%, 5%, 0);
  }
}

@keyframes :global(orbit1) {
  0% { 	transform: rotate(0deg) translate3D(5%, 0, 0) rotate(0deg); }
  25% { 	transform: rotate(90deg) translate3D(1.7%, 0, 0) rotate(-90deg); }
  50% { 	transform: rotate(180deg) translate3D(2%, 0, 0) rotate(-180deg); }
  75% { 	transform: rotate(270deg) translate3D(4%, 0, 0) rotate(-270deg); }
	100%   {  transform: rotate(360deg) translate3D(5%, 0, 0) rotate(-360deg); }
}

@keyframes :global(orbit2) {
  0% { 	transform: rotate(0deg) translate3D(0, 5%, 0) rotate(0deg); }
  25% { 	transform: rotate(-90deg) translate3D(0, 1.7%, 0) rotate(90deg); }
  50% { 	transform: rotate(-180deg) translate3D(0, 2%, 0) rotate(180deg); }
  75% { 	transform: rotate(-270deg) translate3D(0, 4%, 0) rotate(270deg); }
	100%   {  transform: rotate(-360deg) translate3D(0, 5%, 0) rotate(360deg); }
}

@keyframes :global(orbit3) {
  0% { 	transform: rotate(0deg) translate3D(0%, 0, 0) rotate(0deg); }
  25% { 	transform: rotate(90deg) translate3D(1.7%, 0, 0) rotate(-90deg); }
  50% { 	transform: rotate(180deg) translate3D(3%, 0, 0) rotate(-180deg); }
  75% { 	transform: rotate(270deg) translate3D(2%, 0, 0) rotate(-270deg); }
  100%   {  transform: rotate(360deg) translate3D(0%, 0, 0) rotate(-360deg); }
}