:global {
  :root {
    --color-black: 0, 0, 0; /* #000000 */
    --color-white: 255, 255, 255; /* #FFFFFF */
    --color-gray: 244, 244, 244; /* #F4F4F4 */
    --color-gray-200: 229, 231, 235; /* #E5E7EB */
    --color-silver: 199, 202, 209; /* #C7CAD1 */
    --color-darkgray: 107, 114, 128; /* #6B7280 */
    --color-metal: 40, 45, 55; /* #282D37 */
    --color-edit: 255, 0, 255; /* #FF00FF */
    --color-red: 239, 68, 68; /* #EF4444 */
    --color-green: 16, 185, 129; /* #10B981 */
    --color-blue: 0, 117, 255; /* #0075FF */

    --unit-0: 0 !important;
    --unit-px: 1px;
    --unit-1: 0.25rem;
    --unit-2: 0.5rem;
    --unit-3: 0.75rem;
    --unit-4: 1rem;
    --unit-5: 1.25rem;
    --unit-6: 1.5rem;
    --unit-7: 1.75rem;
    --unit-8: 2rem;

    --fontsize-xs: 12px;
    --fontsize-sm: 14px;
    --fontsize-base: 16px;
    --fontsize-lg: 20px;
    --fontsize-xl: 24px;

    --border-opacity: 1;
    --bg-opacity: 1;
    --color-opacity: 1;
  }

  .hover\:border-edit:hover,
  .border-edit {
    border-color: rgba(var(--color-edit), var(--border-opacity));
  }
  .hover\:bg-edit:hover,
  .bg-edit {
    background-color: rgba(var(--color-edit), var(--bg-opacity));
  }
  .hover\:text-edit:hover,
  .text-edit {
    color: rgba(var(--color-edit), var(--color-opacity));
  }

  .hover\:border-black:hover,
  .border-black {
    border-color: rgba(var(--color-black), var(--border-opacity));
  }
  .hover\:bg-black:hover,
  .bg-black {
    background-color: rgba(var(--color-black), var(--bg-opacity));
  }
  .hover\:text-black:hover,
  .text-black {
    color: rgba(var(--color-black), var(--color-opacity));
  }

  .hover\:border-white:hover,
  .border-white {
    border-color: rgba(var(--color-white), var(--border-opacity));
  }
  .hover\:bg-white:hover,
  .bg-white {
    background-color: rgba(var(--color-white), var(--bg-opacity));
  }
  .hover\:text-white:hover,
  .text-white {
    color: rgba(var(--color-white), var(--color-opacity));
  }

  .hover\:border-gray:hover,
  .border-gray {
    border-color: rgba(var(--color-gray), var(--border-opacity));
  }
  .hover\:bg-gray:hover,
  .bg-gray {
    background-color: rgba(var(--color-gray), var(--bg-opacity));
  }
  .hover\:bg-gray-300:hover,
  .bg-gray-200 {
    background-color: rgba(var(--color-gray-200), var(--bg-opacity));
  }
  .hover\:text-gray:hover,
  .text-gray {
    color: rgba(var(--color-gray), var(--color-opacity));
  }

  .hover\:border-silver:hover,
  .border-silver {
    border-color: rgba(var(--color-silver), var(--border-opacity));
  }
  .hover\:bg-silver:hover,
  .bg-silver {
    background-color: rgba(var(--color-silver), var(--bg-opacity));
  }
  .hover\:text-silver:hover,
  .text-silver {
    color: rgba(var(--color-silver), var(--color-opacity));
  }

  .hover\:border-darkgray:hover,
  .border-darkgray {
    border-color: rgba(var(--color-darkgray), var(--border-opacity));
  }
  .hover\:bg-darkgray:hover,
  .bg-darkgray {
    background-color: rgba(var(--color-darkgray), var(--bg-opacity));
  }
  .hover\:text-darkgray:hover,
  .text-darkgray {
    color: rgba(var(--color-darkgray), var(--color-opacity));
  }

  .hover\:border-metal:hover,
  .border-metal {
    border-color: rgba(var(--color-metal), var(--border-opacity));
  }
  .hover\:bg-metal:hover,
  .bg-metal {
    background-color: rgba(var(--color-metal), var(--bg-opacity));
  }
  .hover\:text-metal:hover,
  .text-metal {
    color: rgba(var(--color-metal), var(--color-opacity));
  }

  .hover\:border-red:hover,
  .border-red {
    border-color: rgba(var(--color-red), var(--border-opacity));
  }
  .hover\:bg-red:hover,
  .bg-red {
    background-color: rgba(var(--color-red), var(--bg-opacity));
  }
  .hover\:text-red:hover,
  .text-red {
    color: rgba(var(--color-red), var(--color-opacity));
  }

  .hover\:border-green:hover,
  .border-green {
    border-color: rgba(var(--color-green), var(--border-opacity));
  }
  .hover\:bg-green:hover,
  .bg-green {
    background-color: rgba(var(--color-green), var(--bg-opacity));
  }
  .hover\:text-green:hover,
  .text-green {
    color: rgba(var(--color-green), var(--color-opacity));
  }

  .hover\:border-blue:hover,
  .border-blue {
    border-color: rgba(var(--color-blue), var(--border-opacity));
  }
  .hover\:bg-blue:hover,
  .bg-blue {
    background-color: rgba(var(--color-blue), var(--bg-opacity));
  }
  .hover\:text-blue:hover,
  .text-blue {
    color: rgba(var(--color-blue), var(--color-opacity));
  }

  .hover\:border-transparent:hover,
  .border-transparent {
    border-color: transparent;
  }
  .hover\:bg-transparent:hover,
  .bg-transparent {
    background-color: transparent;
  }
  .hover\:text-transparent:hover,
  .text-transparent {
    color: transparent;
  }

  .hover\:border-current:hover,
  .border-current {
    border-color: currentColor;
  }
  .hover\:bg-current:hover,
  .bg-current {
    background-color: currentColor;
  }
  .hover\:text-current:hover,
  .text-current {
    color: currentColor;
  }

  .bg-gradient-collection {
    background-image: linear-gradient(to right, #7000ff, #da00ff);
  }

  .crisp-edges {
    image-rendering: -moz-crisp-edges; /* Firefox */
    image-rendering: -o-crisp-edges; /* Opera */
    image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
  }

  .clamp-2 {
    display: -webkit-box;
    /* autoprefixer: ignore next */
    -webkit-line-clamp: 2;
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;
  }
}
