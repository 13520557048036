.box {
  background-color: #f4f4f5;
  cursor: default;
  display: inline-flex;
  border-radius: 4px;
  border: 2px solid #f0f;
  user-select: none;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
    'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  line-height: 24px;
  position: relative;
  color: black;
  transition: all 0.2s ease 0s;
  overflow: hidden;
}

.panel {
  background-color: #f4f4f5;
  cursor: default;
  display: inline-flex;
  border-radius: 4px;
  border: 2px solid #f4f4f5;
  user-select: none;
  font-size: 14px;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
    'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  line-height: 24px;
  position: relative;
  color: black;
  transition: all 0.2s ease 0s;
  overflow: hidden;
}

.button {
  @extend .box;
  cursor: pointer;
  height: 32px;
  max-width: 100%;
  overflow: hidden;
  min-width: fit-content;

  &Inner {
    background-color: transparent;
    padding: 2px 5px;
    display: flex;
    align-items: center;
    transition: all 0.2s ease 0s;
    pointer-events: none;
    overflow: hidden;
    min-width: 30px;
  }

  &Label {
    position: relative;
    margin: 0 5px;
    color: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    & + .buttonIcon {
      margin-left: -3px;
      margin-right: -3px;
    }
  }

  &Icon {
    position: relative;
    font-size: 26px;
    color: inherit;
    display: flex;
    align-items: center;
    justify-content: center;

    & > i {
      display: block;
    }

    &Splitter {
      width: 1px;
      background-color: rgba(0, 0, 0, 0.1);
      height: 80%;
      margin: 0 5px;
    }
  }

  &IconComponent {
    display: block;
    width: 20px;
    height: 20px;
    color: inherit;
  }

  &PreIcon {
    @extend .buttonIcon;
    font-size: 16px;

    & + .buttonLabel {
      margin-left: 2px;
    }
  }

  &PreIconComponent {
    @extend .buttonIconComponent;

    & + .buttonLabel {
      margin-left: 2px;
    }
  }

  &ProcessingIndicator {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    align-items: center;
    justify-content: center;
    pointer-events: none;
  }
}

.buttonDefault {
  @extend .button;

  &:hover,
  &:focus {
    @extend .buttonHover;
  }

  &:active {
    @extend .buttonActive;
  }
}

.buttonIdle {
  @extend .button;
}

.buttonActive {
  @extend .button;
  transform: translate3d(0, 1px, 0);
  box-shadow: 0 0 15px -3px rgba(0, 0, 0, 0.1);

  & .buttonInner {
    background-color: #f0f;
  }
}

.buttonDisabled {
  @extend .button;
  cursor: not-allowed;
  opacity: 0.5;
}

.buttonHover {
  @extend .button;
  cursor: pointer;

  & .buttonInner {
    background-color: #ff99ff;
  }
}

.buttonProcessing {
  @extend .button;
  cursor: default;

  & .buttonProcessingIndicator {
    display: flex;
  }

  & .buttonInner {
    opacity: 0.5;
  }
}

.buttonSizeSmall {
  height: 24px;

  & > .buttonInner {
    padding: 0px 3px;
    min-width: 22px;
  }

  & .buttonIcon,
  & .buttonPreIcon {
    font-size: 20px;
  }

  & .buttonIconComponent,
  & .buttonPreIconComponent {
    width: 16px;
    width: 16px;
  }
}

.buttonUnflashy {
  border-color: transparent;
  background-color: transparent;
  box-shadow: none;
  color: rgba(0, 0, 0, 0.7);

  &:hover,
  &:focus {
    color: rgba(0, 0, 0, 1);
    & .buttonInner {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }

  &:active {
    color: rgba(0, 0, 0, 1);
    & .buttonInner {
      background-color: rgba(0, 0, 0, 0.3);
    }
  }
}

.buttonGroup {
  @extend .box;
  height: 32px;
  align-items: center;
  overflow: inherit;
  max-width: 100%;

  &Inner {
    display: flex;
    align-items: center;
    padding: 2px 0 2px 5px;
    overflow: hidden;
  }

  &Label {
    position: relative;
    margin: 0 10px 0 5px;
    color: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &PreIcon {
    @extend .buttonIcon;
    font-size: 16px;

    & + .buttonGroupLabel {
      margin-left: 2px;
    }
  }

  &PreIconComponent {
    @extend .buttonIconComponent;

    & + .buttonGroupLabel {
      margin-left: 2px;
    }
  }

  &Buttons {
    position: relative;
    height: 32px;
    display: flex;
    align-items: center;

    &:first-child {
      margin-left: -2px;
    }

    & > .buttonDefault,
    & > .buttonActive,
    & > .buttonDisabled,
    & > .buttonHover,
    & > .buttonProcessing {
      border-radius: 0;
      border-right-width: 0;
    }
  }

  &.small {
    height: 24px;

    & .buttonGroupButtons {
      & > .buttonDefault,
      & > .buttonActive,
      & > .buttonDisabled,
      & > .buttonHover,
      & > .buttonProcessing {
        border-radius: 0;
        border-right-width: 0;
        border-left-width: 0;
      }
    }
  }

  &Vertical {
    flex-direction: column;
    height: auto;
    width: 36px;

    & .buttonGroupLabel {
      display: none;
    }

    & .buttonGroupInner {
      padding-left: 0;
    }

    & .buttonGroupButtons {
      flex-direction: column;
      height: auto;

      &:first-child {
        margin-top: -2px;
        margin-left: 0;
      }

      & > .buttonDefault,
      & > .buttonActive,
      & > .buttonDisabled,
      & > .buttonHover,
      & > .buttonProcessing {
        border-bottom-width: 0;
        border-left-width: 0;
      }

      & .buttonInner {
        height: 30px;
        width: 32px;
        padding: 0;
        justify-content: center;
      }

      & .buttonLabel {
        display: none;
      }
    }
  }
}

.select {
  position: relative;
}

.selectMenu {
  max-height: 180px;
  max-width: 300px;
  overflow-y: scroll;
  padding: 0;
  list-style: none;
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  color: black;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.3);
  outline: 0;
  opacity: 0;
  z-index: 1001;

  &Open {
    opacity: 1;
  }

  &List {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &Item {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0.5rem;
    line-height: 1 !important;
    font-size: 14px !important;
    white-space: nowrap;
    color: inherit;
    cursor: pointer;
    user-select: none;

    &:hover:not(.disabled),
    &:focus,
    &Focus {
      background-color: #f6f6f6;
    }

    &:active {
      font-weight: bold;
    }
  }
}

.chip {
  @extend .panel;
  height: 32px;
  border-radius: 16px;

  &Inner {
    background-color: transparent;
    padding: 2px 5px;
    display: flex;
    align-items: center;
    transition: all 0.2s ease 0s;
    pointer-events: none;
  }

  &Label {
    position: relative;
    margin: 0 5px;
    color: inherit;

    & + .chipIcon {
      margin-left: -3px;
      margin-right: -3px;
    }
  }

  &Icon {
    position: relative;
    font-size: 26px;
    color: inherit;
    display: flex;
    align-items: center;
    justify-content: center;

    & > i {
      display: block;
    }

    &Splitter {
      width: 1px;
      background-color: rgba(0, 0, 0, 0.1);
      height: 80%;
      margin: 0 5px;
    }
  }

  &IconComponent {
    display: block;
    width: 20px;
    height: 20px;
    color: inherit;
  }

  &PreIcon {
    @extend .chipIcon;

    & + .chipLabel {
      margin-left: 2px;
    }
  }

  &PreIconComponent {
    @extend .chipIconComponent;

    & + .chipLabel {
      margin-left: 2px;
    }
  }

  &ProcessingIndicator {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    align-items: center;
    justify-content: center;
    pointer-events: none;
  }
}

.chipDefault {
  @extend .chip;
}

.chipClickable {
  @extend .chip;
  cursor: pointer;

  &:hover,
  &:focus {
    @extend .chipHover;
  }

  &:active {
    @extend .chipActive;
  }
}

.chipIdle {
  @extend .chip;
}

.chipActive {
  @extend .chip;
  transform: translate3d(0, 1px, 0);
  box-shadow: 0 0 15px -3px rgba(0, 0, 0, 0.1);

  & .chipInner {
    background-color: #f0f;
  }
}

.chipDisabled {
  @extend .chip;
  cursor: not-allowed;
  opacity: 0.5;
}

.chipHover {
  @extend .chip;
  cursor: pointer;

  & .chipInner {
    background-color: #ff99ff;
  }
}

.chipProcessing {
  @extend .chip;
  cursor: default;

  & .chipProcessingIndicator {
    display: flex;
  }

  & .chipInner {
    opacity: 0.5;
  }
}

.chipSizeSmall {
  height: 24px;

  & > .chipInner {
    padding: 0px 3px;
  }

  & .chipIcon,
  & .chipPreIcon {
    font-size: 20px;
  }

  & .chipIconComponent,
  & .chipPreIconComponent {
    width: 16px;
    width: 16px;
  }
}

.chipStatusSuccess {
  background-color: #ebffeb;
  border-color: #ebffeb;
  color: #006600;
}

.chipStatusError {
  background-color: #ffebeb;
  border-color: #ffebeb;
  color: #7a0000;
}

.spinner {
  color: #f0f;
}
