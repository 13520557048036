.spinner {
  margin: auto;
  width: 4em;
  text-align: center;
  line-height: 1em;
  z-index: 2;
}

.spinner > div {
  width: 0.5em;
  height: 0.5em;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  margin: 0 0.25em;
  background: #c9cacb;
}

.absoluteCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.transparentBg {
  background-color: #ffffff;
  z-index: 1;
  width: 100%;
  height: 100%;
  min-height: 1000px;
  position: absolute;
  filter: alpha(opacity=80);
  opacity: 0.8;
  -moz-opacity: 0.8;
}

.dark > div {
  background: #999999;
}

.spinner .bounceOne {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounceTwo {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
