:global {
  .static {
    position: static;
  }
  .fixed {
    position: fixed;
  }
  .relative {
    position: relative;
  }
  .absolute {
    position: absolute;
  }
  .relative {
    position: relative;
  }
  .sticky {
    position: sticky;
  }
}
