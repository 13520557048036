.base {
  box-sizing: border-box;
  min-height: 1em;
  min-width: 5em;
}

.base > p + p {
  margin-top: 0;
}

.hide {
  display: none;
}

.editing {
  border-radius: 0.25rem;
  border: 1px solid rgb(229, 231, 235);
  appearance: none;
  padding: 0.25rem 0.5rem;
  background-color: white;
  resize: vertical;
  cursor: text;

  &:hover {
    border-style: dashed;
    border-color: rgb(255, 0, 255);
  }

  &.focus,
  &:focus {
    border-color: rgb(255, 0, 255);
    border-style: solid;
  }
}
