:global {
  /* { height */
  .h-0 {
    height: 0px;
  }
  .h-px {
    height: 1px;
  }
  .h-0\.5 {
    height: 0.125rem;
  }
  .h-1 {
    height: 0.25rem;
  }
  .h-1\.5 {
    height: 0.375rem;
  }
  .h-2 {
    height: 0.5rem;
  }
  .h-2\.5 {
    height: 0.625rem;
  }
  .h-3 {
    height: 0.75rem;
  }
  .h-3\.5 {
    height: 0.875rem;
  }
  .h-4 {
    height: 1rem;
  }
  .h-5 {
    height: 1.25rem;
  }
  .h-6 {
    height: 1.5rem;
  }
  .h-7 {
    height: 1.75rem;
  }
  .h-8 {
    height: 2rem;
  }
  .h-9 {
    height: 2.25rem;
  }
  .h-10 {
    height: 2.5rem;
  }
  .h-11 {
    height: 2.75rem;
  }
  .h-12 {
    height: 3rem;
  }
  .h-14 {
    height: 3.5rem;
  }
  .h-16 {
    height: 4rem;
  }
  .h-20 {
    height: 5rem;
  }
  .h-24 {
    height: 6rem;
  }
  .h-28 {
    height: 7rem;
  }
  .h-30 {
    height: 7.5rem;
  }
  .h-32 {
    height: 8rem;
  }
  .h-34 {
    height: 8.5rem;
  }
  .h-36 {
    height: 9rem;
  }
  .h-40 {
    height: 10rem;
  }
  .h-44 {
    height: 11rem;
  }
  .h-48 {
    height: 12rem;
  }
  .h-52 {
    height: 13rem;
  }
  .h-56 {
    height: 14rem;
  }
  .h-60 {
    height: 15rem;
  }
  .h-64 {
    height: 16rem;
  }
  .h-72 {
    height: 18rem;
  }
  .h-80 {
    height: 20rem;
  }
  .h-96 {
    height: 24rem;
  }
  .h-auto {
    height: auto;
  }
  .h-1\/2 {
    height: 50%;
  }
  .h-1\/3 {
    height: 33.333333%;
  }
  .h-2\/3 {
    height: 66.666667%;
  }
  .h-1\/4 {
    height: 25%;
  }
  .h-2\/4 {
    height: 50%;
  }
  .h-3\/4 {
    height: 75%;
  }
  .h-1\/5 {
    height: 20%;
  }
  .h-2\/5 {
    height: 40%;
  }
  .h-3\/5 {
    height: 60%;
  }
  .h-4\/5 {
    height: 80%;
  }
  .h-1\/6 {
    height: 16.666667%;
  }
  .h-2\/6 {
    height: 33.333333%;
  }
  .h-3\/6 {
    height: 50%;
  }
  .h-4\/6 {
    height: 66.666667%;
  }
  .h-5\/6 {
    height: 83.333333%;
  }
  .h-full {
    height: 100%;
  }
  .h-screen {
    height: 100vh;
  }
  .h-screen-60 {
    height: 60vh;
  }

  /* min-height */
  .min-h-0 {
    min-height: 0px;
  }
  .min-h-full {
    min-height: 100%;
  }
  .min-h-screen {
    min-height: 100vh;
  }
  .min-h-24 {
    min-height: 6rem;
  }
  .min-h-26 {
    min-height: 6.5rem;
  }
  .min-h-40 {
    min-height: 10rem;
  }

  /* max-height */
  .max-h-0 {
    max-height: 0px;
  }
  .max-h-px {
    max-height: 1px;
  }
  .max-h-0\.5 {
    max-height: 0.125rem;
  }
  .max-h-1 {
    max-height: 0.25rem;
  }
  .max-h-1\.5 {
    max-height: 0.375rem;
  }
  .max-h-2 {
    max-height: 0.5rem;
  }
  .max-h-2\.5 {
    max-height: 0.625rem;
  }
  .max-h-3 {
    max-height: 0.75rem;
  }
  .max-h-3\.5 {
    max-height: 0.875rem;
  }
  .max-h-4 {
    max-height: 1rem;
  }
  .max-h-5 {
    max-height: 1.25rem;
  }
  .max-h-6 {
    max-height: 1.5rem;
  }
  .max-h-7 {
    max-height: 1.75rem;
  }
  .max-h-8 {
    max-height: 2rem;
  }
  .max-h-9 {
    max-height: 2.25rem;
  }
  .max-h-10 {
    max-height: 2.5rem;
  }
  .max-h-11 {
    max-height: 2.75rem;
  }
  .max-h-12 {
    max-height: 3rem;
  }
  .max-h-14 {
    max-height: 3.5rem;
  }
  .max-h-16 {
    max-height: 4rem;
  }
  .max-h-20 {
    max-height: 5rem;
  }
  .max-h-24 {
    max-height: 6rem;
  }
  .max-h-28 {
    max-height: 7rem;
  }
  .max-h-32 {
    max-height: 8rem;
  }
  .max-h-36 {
    max-height: 9rem;
  }
  .max-h-40 {
    max-height: 10rem;
  }
  .max-h-44 {
    max-height: 11rem;
  }
  .max-h-48 {
    max-height: 12rem;
  }
  .max-h-52 {
    max-height: 13rem;
  }
  .max-h-56 {
    max-height: 14rem;
  }
  .max-h-60 {
    max-height: 15rem;
  }
  .max-h-64 {
    max-height: 16rem;
  }
  .max-h-72 {
    max-height: 18rem;
  }
  .max-h-80 {
    max-height: 20rem;
  }
  .\!max-h-80 {
    max-height: 20rem !important;
  }
  .max-h-96 {
    max-height: 24rem;
  }
  .max-h-full {
    max-height: 100%;
  }
  .max-h-screen {
    max-height: 100vh;
  }
}
