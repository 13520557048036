.overlay {
  border: 1px dashed rgba(255, 255, 255, 0.2);
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.overlayWithProgress {
}

.overlayHeader {
  flex-basis: auto;
}

.overlayContent {
  flex: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.overlayContentInner {
  flex-basis: auto;
}

.overlayFooter {
  flex-basis: auto;
}
