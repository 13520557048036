.buttonWrapper {
  display: inline-block;
}

.button {
  background: #fff;
  color: #222;
  border: 0;
  vertical-align: bottom;
  height: 34px;
  font-weight: bold;
  font-size: 1em;
  min-width: 34px;
  padding: 0;
}

.button svg {
  fill: #222;
  display: inline-block;
  vertical-align: middle;
}

.button > span {
  fill: #222;
  display: inline-block;
  text-align: center;
  min-width: 24px;
}

.button:hover,
.button:focus {
  background: #f3f3f3;
  outline: 0;
  /* reset for :focus */
}

.active {
  background: #efefef;
  color: #222;
}

.active svg {
  fill: #222;
}
