.hint {
  background-color: #f4f4f5;
  cursor: default;
  display: inline-flex;
  flex-direction: column;
  border-radius: 4px;
  border: 2px solid #f0f;
  user-select: none;
  align-items: flex-start;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  font-size: 14px;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
    'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  position: relative;
  color: black;
  padding: 8px 10px;
  line-height: 1.4;
}
