.page {
  position: absolute;
  top: 0;
  right: 0;
  height: 100vh;
  left: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.pageContent {
  min-height: 90vh;
  min-height: calc(100vh - 160px - 80px - 30px);
}

.headerContainer {
  flex-basis: auto;
}
