.growWrap {
  /* easy way to plop the elements on top of each other and have them both sized based on the tallest one's height */
  display: grid;
}
.growWrap::after {
  font: inherit;
  line-height: inherit;

  /* Note the weird space! Needed to preventy jumpy behavior */
  content: attr(data-replicated-value) ' ';

  /* This is how textarea text behaves */
  white-space: pre-wrap;

  /* Hidden from view, clicks, and screen readers */
  visibility: hidden;
}
.growWrap > textarea {
  /* You could leave this, but after a user resizes, then it ruins the auto sizing */
  resize: none;

  /* Firefox shows scrollbar on growth, you can hide like this. */
  overflow: hidden;
}
.growWrap > textarea,
.growWrap::after {
  /* Place on top of each other */
  grid-area: 1 / 1 / 2 / 2;
}

.textinput {
  &:hover,
  &:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }
}
