.toolbar {
  left: 50%;
  transform: translate(-50%) scale(0);
  position: absolute;
  background: #fff;
  z-index: 9999;
  box-sizing: border-box;
  border-radius: 2px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.25);
  padding: 6px;
  margin-top: -0.75rem;
  white-space: nowrap;
  font-size: 1rem;
}

.toolbar:after,
.toolbar:before {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.toolbar:after {
  border-color: rgba(255, 255, 255, 0);
  border-top-color: #fff;
  border-width: 4px;
  margin-left: -4px;
}

.toolbar:before {
  border-color: rgba(221, 221, 221, 0);
  border-top-color: #fff;
  border-width: 6px;
  margin-left: -6px;
}
