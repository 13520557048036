:global {
  .translate-x-0 {
    --tw-translate-x: 0px;
  }
  .translate-x-px {
    --tw-translate-x: 1px;
  }
  .translate-x-0\.5 {
    --tw-translate-x: 0.125rem;
  }
  .translate-x-1 {
    --tw-translate-x: 0.25rem;
  }
  .translate-x-1\.5 {
    --tw-translate-x: 0.375rem;
  }
  .translate-x-2 {
    --tw-translate-x: 0.5rem;
  }
  .translate-x-2\.5 {
    --tw-translate-x: 0.625rem;
  }
  .translate-x-3 {
    --tw-translate-x: 0.75rem;
  }
  .translate-x-3\.5 {
    --tw-translate-x: 0.875rem;
  }
  .translate-x-4 {
    --tw-translate-x: 1rem;
  }
  .translate-x-5 {
    --tw-translate-x: 1.25rem;
  }
  .translate-x-6 {
    --tw-translate-x: 1.5rem;
  }
  .translate-x-7 {
    --tw-translate-x: 1.75rem;
  }
  .translate-x-8 {
    --tw-translate-x: 2rem;
  }
  .translate-x-9 {
    --tw-translate-x: 2.25rem;
  }
  .translate-x-10 {
    --tw-translate-x: 2.5rem;
  }
  .translate-x-11 {
    --tw-translate-x: 2.75rem;
  }
  .translate-x-12 {
    --tw-translate-x: 3rem;
  }
  .translate-x-14 {
    --tw-translate-x: 3.5rem;
  }
  .translate-x-16 {
    --tw-translate-x: 4rem;
  }
  .translate-x-20 {
    --tw-translate-x: 5rem;
  }
  .translate-x-24 {
    --tw-translate-x: 6rem;
  }
  .translate-x-28 {
    --tw-translate-x: 7rem;
  }
  .translate-x-32 {
    --tw-translate-x: 8rem;
  }
  .translate-x-36 {
    --tw-translate-x: 9rem;
  }
  .translate-x-40 {
    --tw-translate-x: 10rem;
  }
  .translate-x-44 {
    --tw-translate-x: 11rem;
  }
  .translate-x-48 {
    --tw-translate-x: 12rem;
  }
  .translate-x-52 {
    --tw-translate-x: 13rem;
  }
  .translate-x-56 {
    --tw-translate-x: 14rem;
  }
  .translate-x-60 {
    --tw-translate-x: 15rem;
  }
  .translate-x-64 {
    --tw-translate-x: 16rem;
  }
  .translate-x-72 {
    --tw-translate-x: 18rem;
  }
  .translate-x-80 {
    --tw-translate-x: 20rem;
  }
  .translate-x-96 {
    --tw-translate-x: 24rem;
  }
  .translate-x-1\/2 {
    --tw-translate-x: 50%;
  }
  .translate-x-1\/3 {
    --tw-translate-x: 33.333333%;
  }
  .translate-x-2\/3 {
    --tw-translate-x: 66.666667%;
  }
  .translate-x-1\/4 {
    --tw-translate-x: 25%;
  }
  .translate-x-2\/4 {
    --tw-translate-x: 50%;
  }
  .translate-x-3\/4 {
    --tw-translate-x: 75%;
  }
  .translate-x-full {
    --tw-translate-x: 100%;
  }
  .-translate-x-0 {
    --tw-translate-x: 0px;
  }
  .-translate-x-px {
    --tw-translate-x: -1px;
  }
  .-translate-x-0\.5 {
    --tw-translate-x: -0.125rem;
  }
  .-translate-x-1 {
    --tw-translate-x: -0.25rem;
  }
  .-translate-x-1\.5 {
    --tw-translate-x: -0.375rem;
  }
  .-translate-x-2 {
    --tw-translate-x: -0.5rem;
  }
  .-translate-x-2\.5 {
    --tw-translate-x: -0.625rem;
  }
  .-translate-x-3 {
    --tw-translate-x: -0.75rem;
  }
  .-translate-x-3\.5 {
    --tw-translate-x: -0.875rem;
  }
  .-translate-x-4 {
    --tw-translate-x: -1rem;
  }
  .-translate-x-5 {
    --tw-translate-x: -1.25rem;
  }
  .-translate-x-6 {
    --tw-translate-x: -1.5rem;
  }
  .-translate-x-7 {
    --tw-translate-x: -1.75rem;
  }
  .-translate-x-8 {
    --tw-translate-x: -2rem;
  }
  .-translate-x-9 {
    --tw-translate-x: -2.25rem;
  }
  .-translate-x-10 {
    --tw-translate-x: -2.5rem;
  }
  .-translate-x-11 {
    --tw-translate-x: -2.75rem;
  }
  .-translate-x-12 {
    --tw-translate-x: -3rem;
  }
  .-translate-x-14 {
    --tw-translate-x: -3.5rem;
  }
  .-translate-x-16 {
    --tw-translate-x: -4rem;
  }
  .-translate-x-20 {
    --tw-translate-x: -5rem;
  }
  .-translate-x-24 {
    --tw-translate-x: -6rem;
  }
  .-translate-x-28 {
    --tw-translate-x: -7rem;
  }
  .-translate-x-32 {
    --tw-translate-x: -8rem;
  }
  .-translate-x-36 {
    --tw-translate-x: -9rem;
  }
  .-translate-x-40 {
    --tw-translate-x: -10rem;
  }
  .-translate-x-44 {
    --tw-translate-x: -11rem;
  }
  .-translate-x-48 {
    --tw-translate-x: -12rem;
  }
  .-translate-x-52 {
    --tw-translate-x: -13rem;
  }
  .-translate-x-56 {
    --tw-translate-x: -14rem;
  }
  .-translate-x-60 {
    --tw-translate-x: -15rem;
  }
  .-translate-x-64 {
    --tw-translate-x: -16rem;
  }
  .-translate-x-72 {
    --tw-translate-x: -18rem;
  }
  .-translate-x-80 {
    --tw-translate-x: -20rem;
  }
  .-translate-x-96 {
    --tw-translate-x: -24rem;
  }
  .-translate-x-1\/2 {
    --tw-translate-x: -50%;
  }
  .-translate-x-1\/3 {
    --tw-translate-x: -33.333333%;
  }
  .-translate-x-2\/3 {
    --tw-translate-x: -66.666667%;
  }
  .-translate-x-1\/4 {
    --tw-translate-x: -25%;
  }
  .-translate-x-2\/4 {
    --tw-translate-x: -50%;
  }
  .-translate-x-3\/4 {
    --tw-translate-x: -75%;
  }
  .-translate-x-full {
    --tw-translate-x: -100%;
  }
  .translate-y-0 {
    --tw-translate-y: 0px;
  }
  .translate-y-px {
    --tw-translate-y: 1px;
  }
  .translate-y-0\.5 {
    --tw-translate-y: 0.125rem;
  }
  .translate-y-1 {
    --tw-translate-y: 0.25rem;
  }
  .translate-y-1\.5 {
    --tw-translate-y: 0.375rem;
  }
  .translate-y-2 {
    --tw-translate-y: 0.5rem;
  }
  .translate-y-2\.5 {
    --tw-translate-y: 0.625rem;
  }
  .translate-y-3 {
    --tw-translate-y: 0.75rem;
  }
  .translate-y-3\.5 {
    --tw-translate-y: 0.875rem;
  }
  .translate-y-4 {
    --tw-translate-y: 1rem;
  }
  .translate-y-5 {
    --tw-translate-y: 1.25rem;
  }
  .translate-y-6 {
    --tw-translate-y: 1.5rem;
  }
  .translate-y-7 {
    --tw-translate-y: 1.75rem;
  }
  .translate-y-8 {
    --tw-translate-y: 2rem;
  }
  .translate-y-9 {
    --tw-translate-y: 2.25rem;
  }
  .translate-y-10 {
    --tw-translate-y: 2.5rem;
  }
  .translate-y-11 {
    --tw-translate-y: 2.75rem;
  }
  .translate-y-12 {
    --tw-translate-y: 3rem;
  }
  .translate-y-14 {
    --tw-translate-y: 3.5rem;
  }
  .translate-y-16 {
    --tw-translate-y: 4rem;
  }
  .translate-y-20 {
    --tw-translate-y: 5rem;
  }
  .translate-y-24 {
    --tw-translate-y: 6rem;
  }
  .translate-y-28 {
    --tw-translate-y: 7rem;
  }
  .translate-y-32 {
    --tw-translate-y: 8rem;
  }
  .translate-y-36 {
    --tw-translate-y: 9rem;
  }
  .translate-y-40 {
    --tw-translate-y: 10rem;
  }
  .translate-y-44 {
    --tw-translate-y: 11rem;
  }
  .translate-y-48 {
    --tw-translate-y: 12rem;
  }
  .translate-y-52 {
    --tw-translate-y: 13rem;
  }
  .translate-y-56 {
    --tw-translate-y: 14rem;
  }
  .translate-y-60 {
    --tw-translate-y: 15rem;
  }
  .translate-y-64 {
    --tw-translate-y: 16rem;
  }
  .translate-y-72 {
    --tw-translate-y: 18rem;
  }
  .translate-y-80 {
    --tw-translate-y: 20rem;
  }
  .translate-y-96 {
    --tw-translate-y: 24rem;
  }
  .translate-y-1\/2 {
    --tw-translate-y: 50%;
  }
  .translate-y-1\/3 {
    --tw-translate-y: 33.333333%;
  }
  .translate-y-2\/3 {
    --tw-translate-y: 66.666667%;
  }
  .translate-y-1\/4 {
    --tw-translate-y: 25%;
  }
  .translate-y-2\/4 {
    --tw-translate-y: 50%;
  }
  .translate-y-3\/4 {
    --tw-translate-y: 75%;
  }
  .translate-y-full {
    --tw-translate-y: 100%;
  }
  .-translate-y-0 {
    --tw-translate-y: 0px;
  }
  .-translate-y-px {
    --tw-translate-y: -1px;
  }
  .-translate-y-0\.5 {
    --tw-translate-y: -0.125rem;
  }
  .-translate-y-1 {
    --tw-translate-y: -0.25rem;
  }
  .-translate-y-1\.5 {
    --tw-translate-y: -0.375rem;
  }
  .-translate-y-2 {
    --tw-translate-y: -0.5rem;
  }
  .-translate-y-2\.5 {
    --tw-translate-y: -0.625rem;
  }
  .-translate-y-3 {
    --tw-translate-y: -0.75rem;
  }
  .-translate-y-3\.5 {
    --tw-translate-y: -0.875rem;
  }
  .-translate-y-4 {
    --tw-translate-y: -1rem;
  }
  .-translate-y-5 {
    --tw-translate-y: -1.25rem;
  }
  .-translate-y-6 {
    --tw-translate-y: -1.5rem;
  }
  .-translate-y-7 {
    --tw-translate-y: -1.75rem;
  }
  .-translate-y-8 {
    --tw-translate-y: -2rem;
  }
  .-translate-y-9 {
    --tw-translate-y: -2.25rem;
  }
  .-translate-y-10 {
    --tw-translate-y: -2.5rem;
  }
  .-translate-y-11 {
    --tw-translate-y: -2.75rem;
  }
  .-translate-y-12 {
    --tw-translate-y: -3rem;
  }
  .-translate-y-14 {
    --tw-translate-y: -3.5rem;
  }
  .-translate-y-16 {
    --tw-translate-y: -4rem;
  }
  .-translate-y-20 {
    --tw-translate-y: -5rem;
  }
  .-translate-y-24 {
    --tw-translate-y: -6rem;
  }
  .-translate-y-28 {
    --tw-translate-y: -7rem;
  }
  .-translate-y-32 {
    --tw-translate-y: -8rem;
  }
  .-translate-y-36 {
    --tw-translate-y: -9rem;
  }
  .-translate-y-40 {
    --tw-translate-y: -10rem;
  }
  .-translate-y-44 {
    --tw-translate-y: -11rem;
  }
  .-translate-y-48 {
    --tw-translate-y: -12rem;
  }
  .-translate-y-52 {
    --tw-translate-y: -13rem;
  }
  .-translate-y-56 {
    --tw-translate-y: -14rem;
  }
  .-translate-y-60 {
    --tw-translate-y: -15rem;
  }
  .-translate-y-64 {
    --tw-translate-y: -16rem;
  }
  .-translate-y-72 {
    --tw-translate-y: -18rem;
  }
  .-translate-y-80 {
    --tw-translate-y: -20rem;
  }
  .-translate-y-96 {
    --tw-translate-y: -24rem;
  }
  .-translate-y-1\/2 {
    --tw-translate-y: -50%;
  }
  .-translate-y-1\/3 {
    --tw-translate-y: -33.333333%;
  }
  .-translate-y-2\/3 {
    --tw-translate-y: -66.666667%;
  }
  .-translate-y-1\/4 {
    --tw-translate-y: -25%;
  }
  .-translate-y-2\/4 {
    --tw-translate-y: -50%;
  }
  .-translate-y-3\/4 {
    --tw-translate-y: -75%;
  }
  .-translate-y-full {
    --tw-translate-y: -100%;
  }
}
