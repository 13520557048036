:global {
  /* width */
  .w-0 {
    width: 0px;
  }
  .w-px {
    width: 1px;
  }
  .w-0\.5 {
    width: 0.125rem;
  }
  .w-1 {
    width: 0.25rem;
  }
  .w-1\.5 {
    width: 0.375rem;
  }
  .w-2 {
    width: 0.5rem;
  }
  .w-2\.5 {
    width: 0.625rem;
  }
  .w-3 {
    width: 0.75rem;
  }
  .w-3\.5 {
    width: 0.875rem;
  }
  .w-4 {
    width: 1rem;
  }
  .w-5 {
    width: 1.25rem;
  }
  .w-6 {
    width: 1.5rem;
  }
  .w-7 {
    width: 1.75rem;
  }
  .w-8 {
    width: 2rem;
  }
  .w-9 {
    width: 2.25rem;
  }
  .w-10 {
    width: 2.5rem;
  }
  .w-11 {
    width: 2.75rem;
  }
  .w-12 {
    width: 3rem;
  }
  .w-14 {
    width: 3.5rem;
  }
  .w-16 {
    width: 4rem;
  }
  .w-20 {
    width: 5rem;
  }
  .w-24 {
    width: 6rem;
  }
  .w-28 {
    width: 7rem;
  }
  .w-30 {
    width: 7.5rem;
  }
  .w-32 {
    width: 8rem;
  }
  .w-34 {
    width: 8.5rem;
  }
  .w-36 {
    width: 9rem;
  }
  .w-40 {
    width: 10rem;
  }
  .w-44 {
    width: 11rem;
  }
  .w-48 {
    width: 12rem;
  }
  .w-52 {
    width: 13rem;
  }
  .w-56 {
    width: 14rem;
  }
  .w-60 {
    width: 15rem;
  }
  .w-64 {
    width: 16rem;
  }
  .w-72 {
    width: 18rem;
  }
  .w-76 {
    width: 19rem;
  }
  .w-80 {
    width: 20rem;
  }
  .w-96 {
    width: 24rem;
  }
  .w-auto {
    width: auto;
  }
  .w-1\/2 {
    width: 50%;
  }
  .w-1\/3 {
    width: 33.333333%;
  }
  .w-2\/3 {
    width: 66.666667%;
  }
  .w-1\/4 {
    width: 25%;
  }
  .w-2\/4 {
    width: 50%;
  }
  .w-3\/4 {
    width: 75%;
  }
  .w-1\/5 {
    width: 20%;
  }
  .w-2\/5 {
    width: 40%;
  }
  .w-3\/5 {
    width: 60%;
  }
  .w-4\/5 {
    width: 80%;
  }
  .w-1\/6 {
    width: 16.666667%;
  }
  .w-2\/6 {
    width: 33.333333%;
  }
  .w-3\/6 {
    width: 50%;
  }
  .w-4\/6 {
    width: 66.666667%;
  }
  .w-5\/6 {
    width: 83.333333%;
  }
  .w-1\/12 {
    width: 8.333333%;
  }
  .w-2\/12 {
    width: 16.666667%;
  }
  .w-3\/12 {
    width: 25%;
  }
  .w-4\/12 {
    width: 33.333333%;
  }
  .w-5\/12 {
    width: 41.666667%;
  }
  .w-6\/12 {
    width: 50%;
  }
  .w-7\/12 {
    width: 58.333333%;
  }
  .w-8\/12 {
    width: 66.666667%;
  }
  .w-9\/12 {
    width: 75%;
  }
  .w-10\/12 {
    width: 83.333333%;
  }
  .w-11\/12 {
    width: 91.666667%;
  }
  .w-full {
    width: 100%;
  }
  .w-screen {
    width: 100vw;
  }
  .w-min {
    width: min-content;
  }
  .w-max {
    width: max-content;
  }

  /* min-width */
  .min-w-0 {
    min-width: 0px;
  }
  .min-w-full {
    min-width: 100%;
  }
  .min-w-min {
    min-width: min-content;
  }
  .min-w-max {
    min-width: max-content;
  }
  .min-w-24 {
    min-width: 6rem;
  }
  .min-w-26 {
    min-width: 6.5rem;
  }
  .min-w-40 {
    min-width: 10rem;
  }
  .min-w-52 {
    min-width: 13rem;
  }
  .min-w-56 {
    min-width: 15rem;
  }

  /* max-width */
  .max-w-0 {
    max-width: 0rem;
  }
  .max-w-none {
    max-width: none;
  }
  .max-w-xs {
    max-width: 20rem;
  }
  .max-w-sm {
    max-width: 24rem;
  }
  .max-w-md {
    max-width: 28rem;
  }
  .max-w-lg {
    max-width: 32rem;
  }
  .max-w-xl {
    max-width: 36rem;
  }
  .max-w-2xl {
    max-width: 42rem;
  }
  .max-w-3xl {
    max-width: 48rem;
  }
  .max-w-4xl {
    max-width: 56rem;
  }
  .max-w-5xl {
    max-width: 64rem;
  }
  .max-w-6xl {
    max-width: 72rem;
  }
  .max-w-7xl {
    max-width: 80rem;
  }
  .max-w-full {
    max-width: 100%;
  }
  .max-w-min {
    max-width: min-content;
  }
  .max-w-max {
    max-width: max-content;
  }
  .max-w-prose {
    max-width: 65ch;
  }
  .max-w-screen-sm {
    max-width: 640px;
  }
  .max-w-screen-md {
    max-width: 768px;
  }
  .max-w-screen-lg {
    max-width: 1024px;
  }
  .max-w-screen-xl {
    max-width: 1280px;
  }
  .max-w-screen-2xl {
    max-width: 1536px;
  }
}
