:global {
  /* backdrop-filter */
  .backdrop-filter {
    backdrop-filter: var(--tw-backdrop-blur);
    /*
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast)
      var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity)
      var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
      */
  }
  .backdrop-filter-none {
    backdrop-filter: none;
  }

  /* blur */
  .backdrop-blur-none {
    --tw-backdrop-blur: blur(0);
  }
  .backdrop-blur-sm {
    --tw-backdrop-blur: blur(4px);
  }
  .backdrop-blur {
    --tw-backdrop-blur: blur(8px);
  }
  .backdrop-blur-md {
    --tw-backdrop-blur: blur(12px);
  }
  .backdrop-blur-lg {
    --tw-backdrop-blur: blur(16px);
  }
  .backdrop-blur-xl {
    --tw-backdrop-blur: blur(24px);
  }
  .backdrop-blur-2xl {
    --tw-backdrop-blur: blur(40px);
  }
  .backdrop-blur-3xl {
    --tw-backdrop-blur: blur(64px);
  }
}
