.editRoot {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.menuRoot {
  position: absolute;
  top: 17px;
  left: 10px;
  z-index: 10;
}

.progressRoot {
  display: flex;
  height: 100%;
  cursor: default;
  user-select: none;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.progressContent {
  flex: 0;
  white-space: nowrap;
}

.dropzoneRoot {
  display: flex;
  flex-direction: column;
  height: 100%;
  cursor: pointer;
  user-select: none;
}
.dropzoneContent {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex: 1;
}
.dropzoneFooter {
  flex-grow: 0;
  pointer-events: none;
}
.dropzoneActive {
  background: #f0f;
  background: radial-gradient(circle, rgba(0, 0, 0, 0) 0%, rgba(255, 0, 255, 0.5) 100%);
}
