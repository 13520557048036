.position {
  position: fixed;
  left: 50%;
  top: 50%;
  height: 0;
  width: 0;
  z-index: 999999999999;

  &TopRight {
    & > .root {
      right: 0;
      top: 0;
    }
  }

  &BottomLeft {
    & > .root {
      left: 0;
      bottom: 0;
    }
  }
}

.root {
  background-color: white;
  border: 1px solid #ccc;
  display: inline-block;
  position: absolute;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.3);
}

.list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.item {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0.5rem;
  line-height: 1;
  font-size: 14px;
  white-space: nowrap;
  color: black;
  cursor: pointer;
  user-select: none;

  &.disabled {
    color: #ccc;
    cursor: not-allowed;
  }

  &:hover:not(.disabled) {
    background-color: #f6f6f6;
  }

  &:active {
    font-weight: bold;
  }
}

.icon {
  display: inline-block;
  height: 20px;
  width: 20px;
  margin-right: 0.5rem;
}

.separator {
  display: block;
  height: 1px;
  background-color: #eee;
}

.loadingIndicator {
  margin: 5px 50px;
}
