.aspect-ratio-default {
  &::before {
    content: '';
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: 100%;
  }
  &::after {
    /* to clear float */
    content: '';
    display: table;
    clear: both;
  }
}

:global {
  /* Since the aspect-ratio CSS feature has not enough browser support as of 2022-05 we fall back to https://css-tricks.com/aspect-ratio-boxes/#aa-the-pseudo-element-tactic */
  .aspect-ratio-1\/1 {
    @extend .aspect-ratio-default;
  }
  .aspect-ratio-2\/1 {
    @extend .aspect-ratio-default;
    &::before {
      padding-top: calc(1 / 2 * 100%);
    }
  }

  .gap-4 .aspect-ratio-2\/1 {
    &::before {
      padding-top: calc(1 / 2 * 100% - 0.5rem);
    }
  }
}
