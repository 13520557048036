:global {
  /* Styles for top navigation */
  .o-grid.tools-navigation-top .toolsNav ul {
    display: flex;
  }

  .tools-navigation-top .toolsNav ul li a {
    display: flex;
    align-items: center;
    text-decoration: none;
  }

  .tools-navigation-top .toolsNav .toolsIcons {
    padding: 7px;
  }

  .o-grid.tools-navigation-top .toolsNav {
    display: flex;
  }

  .tools-navigation-top .toolsIcons::before {
    top: 0 !important;
  }

  .tools-navigation-top .toolsNavFrame {
    display: flex;
  }

  .c-app__topbar,
  .topbar-container {
    border-bottom: 1px solid #e9ebec;
    margin-left: auto;
    margin-right: auto;
    padding: 10px;
    max-width: 90%;
  }

  .c-app__topbar {
    .menu-item.has-draft {
      /*border-left: $brandguide-page-draft-color solid 3px;*/
      border-left: #ff0000 solid 3px;
    }

    .menu-item.unpublished {
      /*border-left: $brandguide-page-unpublished-color solid 3px;*/
      border-left: #f0f solid 3px;
    }

    .menu-item.active {
      /*border-left: #33373d solid 3px;*/
      background: #f2f2f2;
    }

    .slick-disabled {
      display: none !important;
    }

    .slick-arrow {
      cursor: pointer;
      position: absolute;
      z-index: 999999;
      border: 1px solid #edebeb;
      border-radius: 50%;
      background: white;
    }

    .slick-prev {
      left: -35px;
    }

    .slick-next {
      right: -35px;
      top: 0;
    }

    .slick-list {
      z-index: 100000;
    }

    .slick-left {
      left: 0 !important;
    }

    .slick-slide {
      width: auto !important;
    }

    .tools-container {
      border-right: 2px solid #d3d1d1;
    }

    .previous-arrow {
      width: 38px;
      height: 38px;
      border-radius: 50%;
      border: 1px solid #edebeb;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    .next-arrow {
      width: 38px;
      height: 38px;
      border-radius: 50%;
      border: 1px solid #edebeb;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    .logo {
      text-decoration: none;
      font-size: 25px;
      color: inherit;
      margin-right: 20px;
    }

    .menus {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      list-style: none;
    }

    .menu-item {
      list-style: none;
      position: relative;
      font-size: 14px;
      word-break: break-all;
    }

    .menu-item a {
      display: block;
      font-size: inherit;
      color: inherit;
      text-decoration: none;
    }

    .menu-item .button {
      display: flex;
      align-items: center;
      color: inherit;
      font-size: inherit;
      border: none;
      background-color: transparent;
      cursor: pointer;
      width: 100%;
    }

    .button span {
      margin-left: 3px;
    }

    .menu-item > a,
    .menu-item .button {
      text-align: left;
      padding: 6px 18px;
    }

    .menu-item a:hover,
    .menu-item .button:hover {
      background-color: #f2f2f2;
    }

    .arrow::after {
      content: '';
      display: inline-block;
      margin-left: 0.28em;
      vertical-align: 0.09em;
      border-top: 0.42em solid;
      border-right: 0.32em solid transparent;
      border-left: 0.32em solid transparent;
    }

    .dropdown {
      top: 34px;
      position: absolute;
      left: 0;
      box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08), 0 4px 6px -2px rgba(71, 63, 79, 0.16);
      font-size: 0.875rem;
      /*min-width: 10rem;*/
      width: 200px;
      padding: 3px 0;
      list-style: none;
      background-color: #fff;
      border-radius: 0 0 0.25rem 0.25rem;
      display: block;
      z-index: 999999999;
    }

    .dropdown .dropdown-submenu {
      position: absolute;
      left: 100%;
      top: -7px;
    }
  }

  .text-superscript {
    font-size: 0.6em;
    vertical-align: super;
  }
  .text-xs {
    font-size: 12px; /* 0.75rem */
    line-height: 16px; /* 1rem */
  }
  .text-sm {
    font-size: 14px; /* 0.875rem; /* 14px */
    line-height: 20px; /* 1.25rem; /* 20px */
  }
  .text-base {
    font-size: 16px; /* 1rem; /* 16px */
    line-height: 24px; /* 1.5rem; /* 24px */
  }
  .text-lg {
    font-size: 18px; /* 1.125rem; /* 18px */
    line-height: 28px; /* 1.75rem; /* 28px */
  }
  .text-xl {
    font-size: 20px; /* 1.25rem; /* 20px */
    line-height: 28px; /* 1.75rem; /* 28px */
  }
  .text-2xl {
    font-size: 24px; /* 1.5rem; /* 24px */
    line-height: 32px; /* 2rem; /* 32px */
  }
  .text-3xl {
    font-size: 30px; /* 1.875rem; /* 30px */
    line-height: 36px; /* 2.25rem; /* 36px */
  }
  .text-4xl {
    font-size: 36px; /* 2.25rem; /* 36px */
    line-height: 40px; /* 2.5rem; /* 40px */
  }
  .text-5xl {
    font-size: 48px; /* 3rem; /* 48px */
    line-height: 1;
  }
  .text-6xl {
    font-size: 60px; /* 3.75rem; /* 60px */
    line-height: 1;
  }
  .text-7xl {
    font-size: 72px; /* 4.5rem; /* 72px */
    line-height: 1;
  }
  .text-8xl {
    font-size: 96px; /* 6rem; /* 96px */
    line-height: 1;
  }
  .text-9xl {
    font-size: 128px; /* 8rem; /* 128px */
    line-height: 1;
  }
}

/* Tailwind base classes */
@import './align-items.css';
@import './animation.css';
@import './appearance.css';
@import './aspect-ratio.css';
@import './backdrop-filter.css';
@import './background.css';
@import './border.css';
@import './box.css';
@import './cursor.css';
@import './display.css';
@import './flex.css';
@import './font.css';
@import './gap.css';
@import './grid.css';
@import './height.css';
@import './justify-content.css';
@import './letter-spacing.css';
@import './line-height.css';
@import './margin.css';
@import './object-fit.css';
@import './opacity.css';
@import './outline.css';
@import './overflow.css';
@import './padding.css';
@import './pointer-events.css';
@import './position.css';
@import './rotate.css';
@import './screen-readers.css';
@import './text.css';
@import './top-right-bottom-left.css';
@import './transform.css';
@import './transition.css';
@import './translate.css';
@import './user-select.css';
@import './vertical-align.css';
@import './white-space.css';
@import './width.css';
@import './z-index.css';
@import './cropper.css';

/* custom classes - colors, spacing, ... */
@import './_custom.css';

/* this file is special, thats why it comes last */
@import './keyframe-animations.css';

/* patches */
@import './patches/react-sortable-tree.css';
