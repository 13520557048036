/*
 * These are the default styles from the original RST library.
 * We have them here because the custom themes (like ebm-line) depend on these.
 */

:global {
  .rst__node {
    min-width: 100%;
    white-space: nowrap;
    position: relative;
    text-align: left;
  }
  .rst__nodeContent {
    position: absolute;
    top: 0;
    bottom: 0;
  }
  .rst__lineBlock,
  .rst__absoluteLineBlock {
    height: 100%;
    position: relative;
    display: inline-block;
  }
  .rst__rowWrapper {
    padding: 10px 10px 10px 0;
    height: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .rst__row {
    height: 100%;
    white-space: nowrap;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .rst__rowContentsDragDisabled {
    border-left: solid #bbb 1px;
  }
  .rst__rowContents {
    position: relative;
    height: 100%;
    border: solid #bbb 1px;
    border-left: none;
    -webkit-box-shadow: 0 2px 2px -2px;
    box-shadow: 0 2px 2px -2px;
    padding: 0 5px 0 10px;
    border-radius: 2px;
    min-width: 230px;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    background-color: white;
  }
  .rst__rowContents,
  .rst__rowLabel,
  .rst__rowToolbar,
  .rst__moveHandle,
  .rst__loadingHandle,
  .rst__toolbarButton,
  .rst__rowLabel_NoFlex,
  .rst__rowToolbar_NoFlex {
    display: inline-block;
    vertical-align: middle;
  }
  .rst__rowLabel {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    padding-right: 20px;
  }
  .rst__rowContents,
  .rst__rowLabel,
  .rst__rowToolbar,
  .rst__moveHandle,
  .rst__loadingHandle,
  .rst__toolbarButton,
  .rst__rowLabel_NoFlex,
  .rst__rowToolbar_NoFlex {
    display: inline-block;
    vertical-align: middle;
  }
  .rst__rowToolbar {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .rst__rowTitle {
    font-weight: bold;
    text-decoration: none;
  }
}
