.rightSidebar {
  right: -345px;
  background: #fff !important;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
  transition: right 0.25s, opacity 0.25s;
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: column;
  width: 345px;
  height: 100%;
  color: #ced4da;
  align-items: flex-start;
  flex-wrap: nowrap;
  opacity: 0;
  z-index: 100000;
}

.rightSidebarVisible {
  opacity: 1;
  right: 0;
}

.outer {
  flex: 1;
  overflow: hidden;
  position: relative;
  width: 100%;
}
