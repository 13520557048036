.container {
}

.inputContainer {
  display: flex;
  border: 1px solid #f3f3f3;
}

.searchIcon {
  background: transparent;
  border: none;
  cursor: pointer;
}
