:global {
  .rotate-0 {
    --tw-rotate: 0deg;
  }
  .rotate-1 {
    --tw-rotate: 1deg;
  }
  .rotate-2 {
    --tw-rotate: 2deg;
  }
  .rotate-3 {
    --tw-rotate: 3deg;
  }
  .rotate-6 {
    --tw-rotate: 6deg;
  }
  .rotate-12 {
    --tw-rotate: 12deg;
  }
  .rotate-45 {
    --tw-rotate: 45deg;
  }
  .rotate-90 {
    --tw-rotate: 90deg;
  }
  .rotate-180 {
    --tw-rotate: 180deg;
  }
  .-rotate-180 {
    --tw-rotate: -180deg;
  }
  .-rotate-90 {
    --tw-rotate: -90deg;
  }
  .-rotate-45 {
    --tw-rotate: -45deg;
  }
  .-rotate-12 {
    --tw-rotate: -12deg;
  }
  .-rotate-6 {
    --tw-rotate: -6deg;
  }
  .-rotate-3 {
    --tw-rotate: -3deg;
  }
  .-rotate-2 {
    --tw-rotate: -2deg;
  }
  .-rotate-1 {
    --tw-rotate: -1deg;
  }
}
