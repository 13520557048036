.wrapperSidebarCollapsed {
  margin-right: -345px;
  transform: translateX(-345px);
}

.wrapper {
  min-height: 100vh;
  transition: transform 250ms linear, margin-right 250ms linear;
}

.sidebar {
  min-width: 345px;
  z-index: 1000;
  transition: transform 250ms linear;
  transform: translateX(0%);
}

.sidebarCollapsed {
  margin-right: -345px;
  transform: translateX(-345px);
}

.base {
  /*padding-left: 345px;*/
  transition: transform 0ms linear 250ms;
  min-height: 100vh;
}

.collapsed {
  padding-left: 0;
}

.content {
  position: relative;
  min-height: 100vh;
}

.main {
  height: 100%;
  position: relative;
}
