.child {
  & > .background {
    opacity: 0.2;
  }
  & > .label {
    opacity: 0.8;
    color: black;
  }
}

.selected {
  & > .background {
    opacity: 1;
  }

  & > .label {
    opacity: 1;
    color: inherit;
  }
}

.interactive:not(.selected) {
  @extend .child;

  &:hover {
    @extend .selected;
  }
}

.interactive.selected {
  & > .background::after {
    content: '';
    display: block;
    background-color: #000;
    position: absolute;
    inset: 0;
    opacity: 0;
    transition: opacity 0.2s ease 0s;
  }
  &:hover {
    & > .background::after {
      opacity: 0.2;
    }
  }
}
