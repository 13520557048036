:global {
  /* text-align */
  .text-left {
    text-align: left;
  }
  .text-center {
    text-align: center;
  }
  .text-right {
    text-align: right;
  }
  .text-justify {
    text-align: justify;
  }

  /* text-decoration */
  .hover\:underline:hover,
  .underline {
    text-decoration: underline;
  }
  .hover\:line-through:hover,
  .line-through {
    text-decoration: line-through;
  }
  .hover\:no-underline:hover,
  .no-underline {
    text-decoration: none;
  }

  /* text-underline-offset */
  .underline-offset-auto {
    text-underline-offset: auto;
  }
  .underline-offset-0 {
    text-underline-offset: 0px;
  }
  .underline-offset-1 {
    text-underline-offset: 1px;
  }
  .underline-offset-2 {
    text-underline-offset: 2px;
  }
  .underline-offset-4 {
    text-underline-offset: 4px;
  }
  .underline-offset-8 {
    text-underline-offset: 8px;
  }

  /* text-transform */
  .uppercase {
    text-transform: uppercase;
  }
  .lowercase {
    text-transform: lowercase;
  }
  .capitalize {
    text-transform: capitalize;
  }
  .normal-case {
    text-transform: none;
  }

  /* text-overflow */
  .truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .overflow-ellipsis {
    text-overflow: ellipsis;
  }
  .overflow-clip {
    text-overflow: clip;
  }
}
