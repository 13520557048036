:global {
  .animate-none {
    animation: none;
  }
  .animate-spin {
    animation: spin 1s linear infinite;
  }
  .animate-ping {
    animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
  }
  .animate-pulse {
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  }
  .animate-bounce {
    animation-name: bounce;
    animation-duration: 1s;
    animation-iteration-count: infinite;
  }
  .animate-wobble {
    animation: wobble 4s infinite alternate;
  }
  .animate-swoosh {
    animation: swoosh 4s infinite cubic-bezier(0.02, 0.01, 0.21, 1);
  }
  .animate-orbit1 {
    animation: orbit1 10s infinite linear;
  }
  .animate-orbit2 {
    animation: orbit2 10s infinite linear;
  }
  .animate-orbit3 {
    animation: orbit3 10s infinite linear;
  }
}
