:global {
  .border-solid {
    border-style: solid;
  }
  .border-dashed {
    border-style: dashed;
  }
  .border-dotted {
    border-style: dotted;
  }
  .border-double {
    border-style: double;
  }
  .border-none {
    border-style: none;
  }
  .border-0 {
    border-width: 0px;
  }
  .border-2 {
    border-width: 2px;
  }
  .border-4 {
    border-width: 4px;
  }
  .border-8 {
    border-width: 8px;
  }
  .border {
    border-width: 1px;
  }
  .border-t-0 {
    border-top-width: 0px;
  }
  .border-t-2 {
    border-top-width: 2px;
  }
  .border-t-4 {
    border-top-width: 4px;
  }
  .border-t-8 {
    border-top-width: 8px;
  }
  .border-t {
    border-top-width: 1px;
  }
  .border-r-0 {
    border-right-width: 0px;
  }
  .border-r-2 {
    border-right-width: 2px;
  }
  .border-r-4 {
    border-right-width: 4px;
  }
  .border-r-8 {
    border-right-width: 8px;
  }
  .border-r {
    border-right-width: 1px;
  }
  .border-b-0 {
    border-bottom-width: 0px;
  }
  .border-b-2 {
    border-bottom-width: 2px;
  }
  .border-b-4 {
    border-bottom-width: 4px;
  }
  .border-b-8 {
    border-bottom-width: 8px;
  }
  .border-b {
    border-bottom-width: 1px;
  }
  .border-l-0 {
    border-left-width: 0px;
  }
  .border-l-2 {
    border-left-width: 2px;
  }
  .border-l-4 {
    border-left-width: 4px;
  }
  .border-l-8 {
    border-left-width: 8px;
  }
  .border-l {
    border-left-width: 1px;
  }
  .border-slate-300 {
    border-color: rgb(203 213 225);
  }
  .rounded-none {
    border-radius: 0px;
  }
  .rounded-sm {
    border-radius: 0.125rem;
  }
  .rounded {
    border-radius: 0.25rem;
  }
  .\!rounded {
    border-radius: 0.25rem !important;
  }
  .rounded-md {
    border-radius: 0.375rem;
  }
  .rounded-lg {
    border-radius: 0.5rem;
  }
  .rounded-xl {
    border-radius: 0.75rem;
  }
  .rounded-2xl {
    border-radius: 1rem;
  }
  .rounded-3xl {
    border-radius: 1.5rem;
  }
  .rounded-full {
    border-radius: 9999px;
  }
  .rounded-t-none {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
  .rounded-t-sm {
    border-top-left-radius: 0.125rem;
    border-top-right-radius: 0.125rem;
  }
  .rounded-t {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  .rounded-t-md {
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
  }
  .rounded-t-lg {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }
  .rounded-t-xl {
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
  }
  .rounded-t-2xl {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }
  .rounded-t-3xl {
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
  }
  .rounded-t-full {
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px;
  }
  .rounded-r-none {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .rounded-r-sm {
    border-top-right-radius: 0.125rem;
    border-bottom-right-radius: 0.125rem;
  }
  .rounded-r {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
  .rounded-r-md {
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
  }
  .rounded-r-lg {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
  .rounded-r-xl {
    border-top-right-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
  }
  .rounded-r-2xl {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }
  .rounded-r-3xl {
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
  }
  .rounded-r-full {
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
  }
  .rounded-b-none {
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
  }
  .rounded-b-sm {
    border-bottom-right-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem;
  }
  .rounded-b {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  .rounded-b-md {
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }
  .rounded-b-lg {
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  .rounded-b-xl {
    border-bottom-right-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
  }
  .rounded-b-2xl {
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }
  .rounded-b-3xl {
    border-bottom-right-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }
  .rounded-b-full {
    border-bottom-right-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }
  .rounded-l-none {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
  .rounded-l-sm {
    border-top-left-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem;
  }
  .rounded-l {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  .rounded-l-md {
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }
  .rounded-l-lg {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  .rounded-l-xl {
    border-top-left-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
  }
  .rounded-l-2xl {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }
  .rounded-l-3xl {
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }
  .rounded-l-full {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }
  .rounded-tl-none {
    border-top-left-radius: 0px;
  }
  .rounded-tl-sm {
    border-top-left-radius: 0.125rem;
  }
  .rounded-tl {
    border-top-left-radius: 0.25rem;
  }
  .rounded-tl-md {
    border-top-left-radius: 0.375rem;
  }
  .rounded-tl-lg {
    border-top-left-radius: 0.5rem;
  }
  .rounded-tl-xl {
    border-top-left-radius: 0.75rem;
  }
  .rounded-tl-2xl {
    border-top-left-radius: 1rem;
  }
  .rounded-tl-3xl {
    border-top-left-radius: 1.5rem;
  }
  .rounded-tl-full {
    border-top-left-radius: 9999px;
  }
  .rounded-tr-none {
    border-top-right-radius: 0px;
  }
  .rounded-tr-sm {
    border-top-right-radius: 0.125rem;
  }
  .rounded-tr {
    border-top-right-radius: 0.25rem;
  }
  .rounded-tr-md {
    border-top-right-radius: 0.375rem;
  }
  .rounded-tr-lg {
    border-top-right-radius: 0.5rem;
  }
  .rounded-tr-xl {
    border-top-right-radius: 0.75rem;
  }
  .rounded-tr-2xl {
    border-top-right-radius: 1rem;
  }
  .rounded-tr-3xl {
    border-top-right-radius: 1.5rem;
  }
  .rounded-tr-full {
    border-top-right-radius: 9999px;
  }
  .rounded-br-none {
    border-bottom-right-radius: 0px;
  }
  .rounded-br-sm {
    border-bottom-right-radius: 0.125rem;
  }
  .rounded-br {
    border-bottom-right-radius: 0.25rem;
  }
  .rounded-br-md {
    border-bottom-right-radius: 0.375rem;
  }
  .rounded-br-lg {
    border-bottom-right-radius: 0.5rem;
  }
  .rounded-br-xl {
    border-bottom-right-radius: 0.75rem;
  }
  .rounded-br-2xl {
    border-bottom-right-radius: 1rem;
  }
  .rounded-br-3xl {
    border-bottom-right-radius: 1.5rem;
  }
  .rounded-br-full {
    border-bottom-right-radius: 9999px;
  }
  .rounded-bl-none {
    border-bottom-left-radius: 0px;
  }
  .rounded-bl-sm {
    border-bottom-left-radius: 0.125rem;
  }
  .rounded-bl {
    border-bottom-left-radius: 0.25rem;
  }
  .rounded-bl-md {
    border-bottom-left-radius: 0.375rem;
  }
  .rounded-bl-lg {
    border-bottom-left-radius: 0.5rem;
  }
  .rounded-bl-xl {
    border-bottom-left-radius: 0.75rem;
  }
  .rounded-bl-2xl {
    border-bottom-left-radius: 1rem;
  }
  .rounded-bl-3xl {
    border-bottom-left-radius: 1.5rem;
  }
  .rounded-bl-full {
    border-bottom-left-radius: 9999px;
  }
}
