:global {
  .hover\:opacity-0:hover,
  .opacity-0 {
    opacity: 0;
  }
  .hover\:opacity-5:hover,
  .opacity-5 {
    opacity: 0.05;
  }
  .hover\:opacity-10:hover,
  .opacity-10 {
    opacity: 0.1;
  }
  .hover\:opacity-20:hover,
  .opacity-20 {
    opacity: 0.2;
  }
  .hover\:opacity-25:hover,
  .opacity-25 {
    opacity: 0.25;
  }
  .hover\:opacity-30:hover,
  .opacity-30 {
    opacity: 0.3;
  }
  .hover\:opacity-40:hover,
  .opacity-40 {
    opacity: 0.4;
  }
  .hover\:opacity-50:hover,
  .opacity-50 {
    opacity: 0.5;
  }
  .hover\:opacity-60:hover,
  .opacity-60 {
    opacity: 0.6;
  }
  .hover\:opacity-70:hover,
  .opacity-70 {
    opacity: 0.7;
  }
  .hover\:opacity-75:hover,
  .opacity-75 {
    opacity: 0.75;
  }
  .hover\:opacity-80:hover,
  .opacity-80 {
    opacity: 0.8;
  }
  .hover\:opacity-90:hover,
  .opacity-90 {
    opacity: 0.9;
  }
  .hover\:opacity-95:hover,
  .opacity-95 {
    opacity: 0.95;
  }
  .hover\:opacity-100:hover,
  .opacity-100 {
    opacity: 1;
  }
}
