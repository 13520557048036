:global {
  /* gap */
  .gap-0 {
    gap: 0px;
  }
  .gap-x-0 {
    column-gap: 0px;
  }
  .gap-y-0 {
    row-gap: 0px;
  }
  .gap-px {
    gap: 1px;
  }
  .gap-x-px {
    column-gap: 1px;
  }
  .gap-y-px {
    row-gap: 1px;
  }
  .gap-0\.5 {
    gap: 0.125rem;
  }
  .gap-x-0\.5 {
    column-gap: 0.125rem;
  }
  .gap-y-0\.5 {
    row-gap: 0.125rem;
  }
  .gap-1 {
    gap: 0.25rem;
  }
  .gap-x-1 {
    column-gap: 0.25rem;
  }
  .gap-y-1 {
    row-gap: 0.25rem;
  }
  .gap-1\.5 {
    gap: 0.375rem;
  }
  .gap-x-1\.5 {
    column-gap: 0.375rem;
  }
  .gap-y-1\.5 {
    row-gap: 0.375rem;
  }
  .gap-2 {
    gap: 0.5rem;
  }
  .gap-x-2 {
    column-gap: 0.5rem;
  }
  .gap-y-2 {
    row-gap: 0.5rem;
  }
  .gap-2\.5 {
    gap: 0.625rem;
  }
  .gap-x-2\.5 {
    column-gap: 0.625rem;
  }
  .gap-y-2\.5 {
    row-gap: 0.625rem;
  }
  .gap-3 {
    gap: 0.75rem;
  }
  .gap-x-3 {
    column-gap: 0.75rem;
  }
  .gap-y-3 {
    row-gap: 0.75rem;
  }
  .gap-3\.5 {
    gap: 0.875rem;
  }
  .gap-x-3\.5 {
    column-gap: 0.875rem;
  }
  .gap-y-3\.5 {
    row-gap: 0.875rem;
  }
  .gap-4 {
    gap: 1rem;
  }
  .gap-x-4 {
    column-gap: 1rem;
  }
  .gap-y-4 {
    row-gap: 1rem;
  }
  .gap-5 {
    gap: 1.25rem;
  }
  .gap-x-5 {
    column-gap: 1.25rem;
  }
  .gap-y-5 {
    row-gap: 1.25rem;
  }
  .gap-6 {
    gap: 1.5rem;
  }
  .gap-x-6 {
    column-gap: 1.5rem;
  }
  .gap-y-6 {
    row-gap: 1.5rem;
  }
  .gap-7 {
    gap: 1.75rem;
  }
  .gap-x-7 {
    column-gap: 1.75rem;
  }
  .gap-y-7 {
    row-gap: 1.75rem;
  }
  .gap-8 {
    gap: 2rem;
  }
  .gap-x-8 {
    column-gap: 2rem;
  }
  .gap-y-8 {
    row-gap: 2rem;
  }
  .gap-9 {
    gap: 2.25rem;
  }
  .gap-x-9 {
    column-gap: 2.25rem;
  }
  .gap-y-9 {
    row-gap: 2.25rem;
  }
  .gap-10 {
    gap: 2.5rem;
  }
  .gap-x-10 {
    column-gap: 2.5rem;
  }
  .gap-y-10 {
    row-gap: 2.5rem;
  }
  .gap-11 {
    gap: 2.75rem;
  }
  .gap-x-11 {
    column-gap: 2.75rem;
  }
  .gap-y-11 {
    row-gap: 2.75rem;
  }
  .gap-12 {
    gap: 3rem;
  }
  .gap-x-12 {
    column-gap: 3rem;
  }
  .gap-y-12 {
    row-gap: 3rem;
  }
  .gap-14 {
    gap: 3.5rem;
  }
  .gap-x-14 {
    column-gap: 3.5rem;
  }
  .gap-y-14 {
    row-gap: 3.5rem;
  }
  .gap-16 {
    gap: 4rem;
  }
  .gap-x-16 {
    column-gap: 4rem;
  }
  .gap-y-16 {
    row-gap: 4rem;
  }
  .gap-20 {
    gap: 5rem;
  }
  .gap-x-20 {
    column-gap: 5rem;
  }
  .gap-y-20 {
    row-gap: 5rem;
  }
  .gap-24 {
    gap: 6rem;
  }
  .gap-x-24 {
    column-gap: 6rem;
  }
  .gap-y-24 {
    row-gap: 6rem;
  }
  .gap-28 {
    gap: 7rem;
  }
  .gap-x-28 {
    column-gap: 7rem;
  }
  .gap-y-28 {
    row-gap: 7rem;
  }
  .gap-32 {
    gap: 8rem;
  }
  .gap-x-32 {
    column-gap: 8rem;
  }
  .gap-y-32 {
    row-gap: 8rem;
  }
  .gap-36 {
    gap: 9rem;
  }
  .gap-x-36 {
    column-gap: 9rem;
  }
  .gap-y-36 {
    row-gap: 9rem;
  }
  .gap-40 {
    gap: 10rem;
  }
  .gap-x-40 {
    column-gap: 10rem;
  }
  .gap-y-40 {
    row-gap: 10rem;
  }
  .gap-44 {
    gap: 11rem;
  }
  .gap-x-44 {
    column-gap: 11rem;
  }
  .gap-y-44 {
    row-gap: 11rem;
  }
  .gap-48 {
    gap: 12rem;
  }
  .gap-x-48 {
    column-gap: 12rem;
  }
  .gap-y-48 {
    row-gap: 12rem;
  }
  .gap-52 {
    gap: 13rem;
  }
  .gap-x-52 {
    column-gap: 13rem;
  }
  .gap-y-52 {
    row-gap: 13rem;
  }
  .gap-56 {
    gap: 14rem;
  }
  .gap-x-56 {
    column-gap: 14rem;
  }
  .gap-y-56 {
    row-gap: 14rem;
  }
  .gap-60 {
    gap: 15rem;
  }
  .gap-x-60 {
    column-gap: 15rem;
  }
  .gap-y-60 {
    row-gap: 15rem;
  }
  .gap-64 {
    gap: 16rem;
  }
  .gap-x-64 {
    column-gap: 16rem;
  }
  .gap-y-64 {
    row-gap: 16rem;
  }
  .gap-72 {
    gap: 18rem;
  }
  .gap-x-72 {
    column-gap: 18rem;
  }
  .gap-y-72 {
    row-gap: 18rem;
  }
  .gap-80 {
    gap: 20rem;
  }
  .gap-x-80 {
    column-gap: 20rem;
  }
  .gap-y-80 {
    row-gap: 20rem;
  }
  .gap-96 {
    gap: 24rem;
  }
  .gap-x-96 {
    column-gap: 24rem;
  }
  .gap-y-96 {
    row-gap: 24rem;
  }
}
