:global {
  /* scale */
  .scale-0 {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }
  .scale-50 {
    --tw-scale-x: 0.5;
    --tw-scale-y: 0.5;
  }
  .scale-75 {
    --tw-scale-x: 0.75;
    --tw-scale-y: 0.75;
  }
  .scale-90 {
    --tw-scale-x: 0.9;
    --tw-scale-y: 0.9;
  }
  .scale-95 {
    --tw-scale-x: 0.95;
    --tw-scale-y: 0.95;
  }
  .scale-100 {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }
  .scale-105 {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }
  .scale-110 {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }
  .scale-125 {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }
  .scale-150 {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }

  /* transform */
  .transform {
    transform: translateX(var(--tw-translate-x, 0)) translateY(var(--tw-translate-y, 0)) rotate(var(--tw-rotate, 0))
      skewX(var(--tw-skew-x, 0)) skewY(var(--tw-skew-y, 0)) scaleX(var(--tw-scale-x, 1)) scaleY(var(--tw-scale-y, 1));
  }
  .transform-gpu {
    transform: translate3d(var(--tw-translate-x, 0), var(--tw-translate-y, 0), 0) rotate(var(--tw-rotate, 0))
      skewX(var(--tw-skew-x, 0)) skewY(var(--tw-skew-y, 0)) scaleX(var(--tw-scale-x, 1)) scaleY(var(--tw-scale-y, 1));
  }
  .transform-none {
    transform: none;
  }

  /* transform-origin */
  .origin-center {
    transform-origin: center;
  }
  .origin-top {
    transform-origin: top;
  }
  .origin-top-right {
    transform-origin: top right;
  }
  .origin-right {
    transform-origin: right;
  }
  .origin-bottom-right {
    transform-origin: bottom right;
  }
  .origin-bottom {
    transform-origin: bottom;
  }
  .origin-bottom-left {
    transform-origin: bottom left;
  }
  .origin-left {
    transform-origin: left;
  }
  .origin-top-left {
    transform-origin: top left;
  }
}
