:global {
  /* font-smoothing */
  .antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .subpixel-antialiased {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
  }

  /* font-style */
  .italic {
    font-style: italic;
  }
  .not-italic {
    font-style: normal;
  }

  /* font-weight */
  .font-thin {
    font-weight: 100;
  }
  .font-extralight {
    font-weight: 200;
  }
  .font-light {
    font-weight: 300;
  }
  .font-normal {
    font-weight: 400;
  }
  .font-medium {
    font-weight: 500;
  }
  .font-semibold {
    font-weight: 600;
  }
  .font-bold {
    font-weight: 700;
  }
  .font-extrabold {
    font-weight: 800;
  }
  .font-black {
    font-weight: 900;
  }

  /* font-size */
  .text-xs {
    font-size: var(--fontsize-xs);
  }
  .text-sm {
    font-size: var(--fontsize-sm);
  }
  .text-base {
    font-size: var(--fontsize-base);
  }
  .text-lg {
    font-size: var(--fontsize-lg);
  }
  .text-xl {
    font-size: var(--fontsize-xl);
  }

  /* font-family */
  .font-sans {
    font-family: 'Inter var', ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
      'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
      'Noto Color Emoji';
  }
  .font-serif {
    font-family: ui-serif, Georgia, Cambria, 'Times New Roman', Times, serif;
  }
  .font-mono {
    font-family: 'Fira Code VF', ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New',
      monospace;
  }
}
