:global {
  /* box-sizing */
  .box-border {
    box-sizing: border-box;
  }
  .box-content {
    box-sizing: content-box;
  }

  /* box-shadow */
  .hover\:shadow-sm:hover,
  .shadow-sm {
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  }
  .hover\:shadow:hover,
  .shadow {
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  }
  .hover\:shadow-md:hover,
  .shadow-md {
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  }
  .hover\:shadow-lg:hover,
  .shadow-lg {
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  }
  .hover\:shadow-xl:hover,
  .shadow-xl {
    box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
  }
  .hover\:shadow-2xl:hover,
  .shadow-2xl {
    box-shadow: 0 20px 50px -12px rgb(0 0 0 / 0.5);
  }
  .hover\:shadow-inner:hover,
  .shadow-inner {
    box-shadow: inset 0 2px 4px 0 rgb(0 0 0 / 0.05);
  }
  .hover\:shadow-none:hover,
  .shadow-none {
    box-shadow: 0 0 #0000;
  }
}
