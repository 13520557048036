:global {
  .pt-0,
  .p-0,
  .py-0 {
    padding-top: var(--unit-0);
  }
  .pr-0,
  .p-0,
  .px-0 {
    padding-right: var(--unit-0);
  }
  .pb-0,
  .p-0,
  .py-0 {
    padding-bottom: var(--unit-0);
  }
  .pl-0,
  .p-0,
  .px-0 {
    padding-left: var(--unit-0);
  }
  .pt-px,
  .p-px,
  .py-px {
    padding-top: var(--unit-px);
  }
  .pr-px,
  .p-px,
  .px-px {
    padding-right: var(--unit-px);
  }
  .pb-px,
  .p-px,
  .py-px {
    padding-bottom: var(--unit-px);
  }
  .pl-px,
  .p-px,
  .px-px {
    padding-left: var(--unit-px);
  }
  .pt-1,
  .p-1,
  .py-1 {
    padding-top: var(--unit-1);
  }
  .pr-1,
  .p-1,
  .px-1 {
    padding-right: var(--unit-1);
  }
  .pb-1,
  .p-1,
  .py-1 {
    padding-bottom: var(--unit-1);
  }
  .pl-1,
  .p-1,
  .px-1 {
    padding-left: var(--unit-1);
  }
  .pt-2,
  .p-2,
  .py-2 {
    padding-top: var(--unit-2);
  }
  .pr-2,
  .p-2,
  .px-2 {
    padding-right: var(--unit-2);
  }
  .pb-2,
  .p-2,
  .py-2 {
    padding-bottom: var(--unit-2);
  }
  .pl-2,
  .p-2,
  .px-2 {
    padding-left: var(--unit-2);
  }
  .pt-3,
  .p-3,
  .py-3 {
    padding-top: var(--unit-3);
  }
  .pr-3,
  .p-3,
  .px-3 {
    padding-right: var(--unit-3);
  }
  .pb-3,
  .p-3,
  .py-3 {
    padding-bottom: var(--unit-3);
  }
  .pl-3,
  .p-3,
  .px-3 {
    padding-left: var(--unit-3);
  }
  .pt-4,
  .p-4,
  .py-4 {
    padding-top: var(--unit-4);
  }
  .pr-4,
  .p-4,
  .px-4 {
    padding-right: var(--unit-4);
  }
  .pb-4,
  .p-4,
  .py-4 {
    padding-bottom: var(--unit-4);
  }
  .pl-4,
  .p-4,
  .px-4 {
    padding-left: var(--unit-4);
  }
  .pt-5,
  .p-5,
  .py-5 {
    padding-top: var(--unit-5);
  }
  .pr-5,
  .p-5,
  .px-5 {
    padding-right: var(--unit-5);
  }
  .pb-5,
  .p-5,
  .py-5 {
    padding-bottom: var(--unit-5);
  }
  .pl-5,
  .p-5,
  .px-5 {
    padding-left: var(--unit-5);
  }

  .pt-6,
  .p-6,
  .py-6 {
    padding-top: var(--unit-6);
  }
  .pr-6,
  .p-6,
  .px-6 {
    padding-right: var(--unit-6);
  }
  .pb-6,
  .p-6,
  .py-6 {
    padding-bottom: var(--unit-6);
  }
  .pl-6,
  .p-6,
  .px-6 {
    padding-left: var(--unit-6);
  }

  .pt-7,
  .p-7,
  .py-7 {
    padding-top: var(--unit-7);
  }
  .pr-7,
  .p-7,
  .px-7 {
    padding-right: var(--unit-7);
  }
  .pb-7,
  .p-7,
  .py-7 {
    padding-bottom: var(--unit-7);
  }
  .pl-7,
  .p-7,
  .px-7 {
    padding-left: var(--unit-7);
  }

  .pt-8,
  .p-8,
  .py-8 {
    padding-top: var(--unit-8);
  }
  .pr-8,
  .p-8,
  .px-8 {
    padding-right: var(--unit-8);
  }
  .pb-8,
  .p-8,
  .py-8 {
    padding-bottom: var(--unit-8);
  }
  .pl-8,
  .p-8,
  .px-8 {
    padding-left: var(--unit-8);
  }
  .pt-64 {
    padding-top: 16rem;
  }
}
